import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import pricesPayment from 'Utils/pricesPayment';
import datesRange from 'Utils/datesRange';

import Price from 'Shared/ui/Price';

import TitleWithTraveller from './TitleWithTraveller';
import TitleWithoutTraveller from './TitleWithoutTraveller';
import { RowFooterItem } from 'pages/Orders/containers/List/components/Orders/Orders.styled';

@inject('ordersStore', 'store')
@observer
class Slot extends Component {
  get isBankTransfer() {
    const {
      payment: { cash, bank_transfer }
    } = this;
    return !!bank_transfer && !cash;
  }

  get isCash() {
    const {
      payment: { cash, bank_transfer }
    } = this;
    return !!cash && !bank_transfer;
  }

  get isMixed() {
    const { isBankTransfer, isCash } = this;
    return !isBankTransfer && !isCash;
  }

  get payment() {
    const {
      tariff,
      slot: { prices }
    } = this.props;
    return pricesPayment({ tariff, prices });
  }

  get hasTravellers() {
    const {
      slot: { travellers }
    } = this.props;
    return travellers.length > 0;
  }

  datesPeriod({ check_in, check_out }) {
    return datesRange(check_in, check_out, 'D MMM');
  }

  unsetDownload = () => {
    this.isDownload = false;
  };

  handleDownload = (slotId) => {
    const { ordersStore, reservationId } = this.props;
    ordersStore
      .downloadLetter(reservationId, slotId)
      .then((_r) => this.unsetDownload())
      .catch((error) => this.errorSubmitHandler(error) && this.unsetDownload());
  };

  errorSubmitHandler = (error) => {
    const { store } = this.props;
    const { data } = error.response;

    store.notifyStore.create({
      header: 'Произошла ошибка!',
      type: 'error',
      messages: data.messages
    });
  };

  render() {
    const { number, slot } = this.props;

    const {
      datesPeriod,
      isBankTransfer,
      isCash,
      isMixed,
      hasTravellers,
      payment: { total, bank_transfer, cash }
    } = this;

    const priceCls = classNames('policies__column price', {
      cash: isCash,
      bank_transfer: isBankTransfer,
      mixed: isMixed
    });

    return (
      <div className="policies__row">
        <div className="policy__content">
          <div className="policies__number">{number}</div>

          {hasTravellers
            ? (
            <TitleWithTraveller slot={slot} />
              )
            : (
            <TitleWithoutTraveller slot={slot} />
              )}

          <div className={'column_with_download'}>
            <div className={priceCls}>
              <Price value={total.price} />

              {slot?.state === 'confirmed' && (
                <>
                  {isCash && (
                    <span className="text">
                      <FormattedMessage id="orders.payment_method.cash" />
                    </span>
                  )}

                  {isBankTransfer && (
                    <span className="text">
                      <FormattedMessage id="orders.payment_method.bank_transfer" />
                    </span>
                  )}

                  {isMixed && (
                    <span className="text">
                      <FormattedMessage id="orders.payment_method.total" />
                    </span>
                  )}
                </>
              )}
            </div>
            {slot?.has_guarantee_letter && (
              <RowFooterItem onClick={() => this.handleDownload(slot.id)}>
                <FormattedMessage id="shared.downloadLetter" />
              </RowFooterItem>
            )}
          </div>
        </div>
        {isMixed && (
          <div className="policy__payments">
            <div className="policy__payment">
              <div className="description">
                <FormattedMessage id="orders.payment_method.bank_transfer" />,
                &nbsp;
                {datesPeriod(bank_transfer)}
                &nbsp; (
                <FormattedMessage
                  id="ui.duration"
                  values={bank_transfer.duration}
                />
                )
              </div>
              <div className="value">
                <Price value={bank_transfer.price} />
              </div>
            </div>

            <div className="policy__payment">
              <div className="description">
                <FormattedMessage id="orders.payment_method.cash" />, &nbsp;
                {datesPeriod(cash)}
                &nbsp; (
                <FormattedMessage id="ui.duration" values={cash.duration} />)
              </div>

              <div className="value cash">
                <Price value={cash.price} />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

Slot.propTypes = {
  number: PropTypes.number.isRequired,
  slot: PropTypes.object.isRequired,
  tariff: PropTypes.object.isRequired,
  reservationId: PropTypes.string.isRequired,
  ordersStore: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired
};

export default Slot;
